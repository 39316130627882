import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";
import Content_StorageOptimized from "components/content/feature_storage-optimized.js";
import Content_StorageMigration from "components/content/feature_storage-migration.js";

const Page = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="notion-backup"
      title="Automated Notion Backup Service"
      description="Automate Notion backups, schedule them, and securely store on any provider. Easily restore lost data and keep your workspace safe with zero extra effort."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automated  <span className="text-primary">Notion </span> backups</>)}
      subTitle={(
        <>Set up automatic backups for your Notion workspace, schedule them, and securely store them anywhere.<br/>
          Restore lost data, and keep your information safe without any extra effort.
        </>)}
      icons={[
        <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Fully integrated with:</p>,
        <Ui.Image filename={`brands/icons/notion-ico.png`} alt={`Notion backup`}  className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your Notion Backup" urlAtrr={{ sb_source: "website", sb_term: `notion-hero` }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-app-saas-app-notion.png" alt="SimpleBackups Notion backup"/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "Backup all your Notion data",
                title: (<>Get peace of mind and secure your <span className="text-primary">Notion data</span></>),
                description: (
                  <>
                    <p>
                      Say goodbye to manual backups. SimpleBackups automates the entire process, <span className="link-highlight">ensuring your Notion workspaces are consistently backed up</span> without any hassle.
                      <Ui.FeatureChecks items={['Notion Workspaces', 'Notion Databases', 'Notion Pages', 'Notion Files']} />
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-backup-expert.png'
                  alt="Optimized MySQL backup service"
                  style={{maxWidth: 450}}
                />)
              },
              Content_FeatureControl({subject: 'Notion'}),
              Content_FeatureTrust(),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">What Makes Notion Backups Essential?</Ui.Typography>

          <div className="grid md:grid-cols-4 md:gap-4 gap-4 text-left mt-16">
            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-binary-lock text-primary text-4xl mb-4" />
              <h3 className="text-lg font-bold mb-1 h-8 block">Continuous Protection</h3>
              <p className="text-base">
                SimpleBackups ensures your Notion workspaces are backed up at <span className="link-highlight">regular intervals</span>, providing continuous protection for your data.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-flask-gear text-primary text-4xl mb-4" />
              <h3 className="text-lg font-bold mb-1 h-8 block">Secure & Granular Backup</h3>
              <p className="text-base">Backup the Notion data you want: <span className="link-highlight">pages, databases, files or entire workspaces</span>.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-bell-concierge text-primary text-4xl mb-4" />
              <h3 className="text-lg font-bold mb-1 h-8 block">Flexible Storage</h3>
              <p className="text-base">Choose from <span className="link-highlight">multiple cloud storage providers or any S3 compatabile storage</span> to store your backups securely.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-arrow-rotate-left text-primary text-4xl mb-4" />
              <h3 className="text-lg font-bold mb-1 h-8 block">One-click Restoration</h3>
              <p className="text-base">Restore your entire workspace or individual pages with a single click with <span className="link-highlight">point-in-time Notion restore</span>.
              </p>
            </div>
          </div>
          <div className="text-center mt-10 mb-6">
            <Ui.Typography tag="h3" className="text-2xl text-center">Wait... there's even more...</Ui.Typography>
          </div>
          <Sections.FeatureSlider tags={["notion"]} />

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>


    <Sections.SectionLearn
      title={<>Wanna learn more about Notion backup?</>}
      text={`Find out more and get started with our Notion Backup guides`}
      tags={['saas-backup', 'saas-notion']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['what_notion_backup_service', 'what_notion_backup_data_types', 'notion_is_there_a_data_limit']}/>

  </Layout.Layout>
)

export default Page
